import React, { useState } from "react";
import Chart from "react-apexcharts";
import { useDarkMode } from "@hooks/dark-mode";
import useAnalytics from "@hooks/analytics";
import { twMerge } from "tailwind-merge";

// CSS styles for the tooltip
const tooltipStyles: React.CSSProperties = {
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
  color: "gray",
  marginLeft: "8px",
  padding: "4px",
  border: "1px solid #000",
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  textAlign: "center",
  lineHeight: "12px"
};

const tooltipTextStyles: React.CSSProperties = {
  visibility: "hidden",
  width: "125px",
  height: "75pxpx",
  backgroundColor: "#555",
  color: "#fff",
  textAlign: "center",
  borderRadius: "6px",
  padding: "5px 0",
  position: "absolute",
  zIndex: 1,
  bottom: "125%",
  left: "50%",
  marginLeft: "-75px",
  opacity: 0,
  transition: "opacity 0.3s",
};

const tooltipContainerStyles: React.CSSProperties = {
  ...tooltipTextStyles,
  visibility: "visible",
  opacity: 1,
};

// The Big Emissions Chart
const BigEmissions: React.FC = () => {
  // Analytics
  const analytics = useAnalytics();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const getEmissonDiff = () => {
    const endEmissions = Number(analytics.recentAnalytics[analytics.formattedEnd]?.totalEmissions ?? 0);
    const startEmissions = Number(analytics.recentAnalytics[analytics.formattedStart]?.totalEmissions ?? 0);
    return endEmissions - startEmissions;
  };

  const emissionDiff = getEmissonDiff();
  const formattedEmissionDiff = emissionDiff > 0 ? `+${emissionDiff.toFixed(2)}` : `${emissionDiff.toFixed(2)}`;
  const tooltipText = emissionDiff > 0 
    ? `You spent  ${formattedEmissionDiff} kg compared to the last time period ` 
    : `You saved  ${formattedEmissionDiff} kg this time period`;

  return (
    <div className="rounded-lg bg-secBg p-4 shadow sm:p-6 xl:p-8">
      <div className="mb-4 flex items-center justify-between">
        <div className="shrink-0">
          <span className="text-2xl font-bold leading-none text-black sm:text-3xl">
            {Object.values(analytics.recentAnalytics).length === 0
              ? 0
              : Object.values(analytics.recentAnalytics).map(ana => ana.totalEmissions).reduce((ana1, ana2) => ana1 + ana2).toFixed(2)}{" "}
            kg
          </span>
          <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
          Co2 Emissions
          </h3>
        </div>
        <div className="flex items-center">
          <div
            className={twMerge([
              "flex items-center justify-end text-base font-bold",
              emissionDiff > 0
                ? "text-red-600 dark:text-red-400"
                : emissionDiff < 0
                ? "text-green-600 dark:text-green-400"
                : "text-gray-600 dark:text-gray-400"
            ])}
            style={{ marginRight: "15px" }} 
          >
            {formattedEmissionDiff}kg
            <span
              style={tooltipStyles}
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              ?
              <span style={tooltipVisible ? tooltipContainerStyles : tooltipTextStyles}>
                {tooltipText}
              </span>
            </span>
          </div>
        </div>
      </div>
      <BigEmissionsChart />
    </div>
  );
};

// The Chart For The Big Emissions Chart Component
const BigEmissionsChart: React.FC = () => {
  const isDarkTheme = useDarkMode();

  const borderColor = isDarkTheme.dark ? "#374151" : "#F3F4F6";
  const labelColor = isDarkTheme.dark ? "#93ACAF" : "#6B7280";
  const opacityFrom = isDarkTheme.dark ? 0 : 0.45;
  const opacityTo = isDarkTheme.dark ? 0.15 : 0;

  const analytics = useAnalytics();

  const options: ApexCharts.ApexOptions = {
    stroke: {
      curve: "smooth"
    },
    chart: {
      type: "area",
      fontFamily: "Inter, sans-serif",
      foreColor: labelColor,
      toolbar: {
        show: true
      },
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom,
        opacityTo,
        type: "vertical"
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      hideEmptySeries: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif"
      }
    },
    grid: {
      show: true,
      borderColor: borderColor,
      strokeDashArray: 1,
      padding: {
        left: 35,
        bottom: 15
      }
    },
    markers: {
      size: 5,
      strokeColors: "#ffffff",
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    xaxis: {
      type: "datetime",
      categories: Object.keys(analytics.recentAnalytics)
        .sort((rec1, rec2) =>
          new Date(rec1).getDate() > new Date(rec2).getDate() ? 1 : -1
        )
        .map(val => new Date(val).getTime()),
      labels: {
        style: {
          colors: [labelColor],
          fontSize: "14px",
          fontWeight: 500
        }
      },
      axisBorder: {
        color: borderColor
      },
      axisTicks: {
        color: borderColor
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 10
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: [labelColor],
          fontSize: "14px",
          fontWeight: 500
        },
        formatter: value => `${value.toFixed(2)}kg`
      }
    },
    legend: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      labels: {
        colors: [labelColor]
      },
      itemMargin: {
        horizontal: 10
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          xaxis: {
            labels: {
              show: false
            }
          }
        }
      }
    ]
  };

  const series: ApexAxisChartSeries = [
    {
      name: "Emissions",
      data: Object.entries(analytics.recentAnalytics)
        .sort(([date1], [date2]) => new Date(date1).getTime() - new Date(date2).getTime())
        .map(([date, ana]) => ({ x: new Date(date).getTime(), y: Number(ana.totalEmissions ?? 0) })),
      color: isDarkTheme.primary
    }
  ];

  return <Chart height={420} options={options} series={series} type="area" />;
};

// Exporting
export default BigEmissions;
