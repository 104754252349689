import { useCallback, useEffect, useState } from "react";

// Helpers
import {
  addDepartment as dispatchDepartmentCreate,
  updateDepartment as dispatchDepartmentUpdate,
  addDepartments,
  Err,
  useAppSelector,
  useAppDispatch,
  departmentError,
  startDepartmentUpdate
} from "@util";
import { useApi, useOrgCode } from "@hooks/api";
import { useUser } from "@hooks/user";
import { toast } from "react-toastify";

const useDepartments = () => {
  // The User's Departments
  const { departments, loading, error, total, tempDepartment } = useAppSelector(
    state => state.departments
  );

  // The User's Offset
  const [offset, setOffset] = useState(0);

  // The Limit For The Departments
  const [limit, setLimit] = useState(20);

  // The User
  const user = useUser();

  // The API
  const api = useApi();

  // The App Dispatch
  const dispatch = useAppDispatch();

  /**
   * Create A New Department
   *
   * @param Department - The Department For The API Call
   */
  const addDepartment = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing Default
    event.preventDefault();

    // Making Sure The Department Exists
    if (tempDepartment === undefined) {
      throw new Error("should not happen");
    }

    // Setting Loading
    dispatch(startDepartmentUpdate());

    // Setting The Toast
    const id = toast.loading("Creating Department...");

    // Fetch The Departments
    api
      .requestWithJSON<Drivn.Department>(
        "/organization/department",
        true,
        "POST",
        tempDepartment
      )
      .then(([, res]) => {
        // Handling Errors
        if (isError(res)) {
          dispatch(departmentError([res, id]));
          return;
        }

        // Updating The Toast
        toast.update(id, {
          render: "Department created successfully",
          type: "success",
          autoClose: 5000,
          isLoading: false
        });

        // Set The Departments
        dispatch(dispatchDepartmentCreate(res));
      })
      .catch(err => api.handleErrors(err, departmentError, id));
  };

  const isError = (object: any): object is Err =>
    "relatedVar" in object && "name" in object && "message" in object;

  /**
   * Update An Existing Department
   *
   * @param update - The Department Fields To Update
   */
  const updateDepartment = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing Default
    event.preventDefault();

    // Making Sure The Department Exists
    if (tempDepartment === undefined) {
      throw new Error("should not happen");
    }

    // Setting Loading
    dispatch(startDepartmentUpdate());

    // Setting The Toast
    const id = toast.loading("Updating Department...");

    // Fetch The Departments
    api
      .requestWithJSON<Drivn.Department>(
        `/organization/department/${tempDepartment.id!}`,
        true,
        "PUT",
        tempDepartment
      )
      .then(([, res]) => {
        // Handling Errors
        if (isError(res)) {
          dispatch(departmentError([res, id]));
          return;
        }

        // Updating The Toast
        toast.update(id, {
          render: "Department updated successfully",
          type: "success",
          autoClose: 5000,
          isLoading: false
        });

        // Set The Departments
        dispatch(dispatchDepartmentUpdate(res));
      })
      .catch(err => api.handleErrors(err, departmentError, id));
  };

  /**
   * Fetch Departments
   */
  const fetchDepartments = useCallback(
    (first: boolean = false) => {
      // Making Sure The Departments Don't Exist
      if (loading || error !== undefined || departments.length === total) {
        return;
      }

      // Setting Loading
      dispatch(startDepartmentUpdate());

      // Fetch The Departments
      api
        .requestWithJSON<Drivn.DepartmentResponse>(
          `/organization/department/all/${useOrgCode}?limit=${limit}&offset=${offset}`,
          true,
          "GET"
        )
        .then(([, res]) => {
          // Handling Errors
          if (res instanceof Err) {
            dispatch(departmentError([res, undefined]));
            return;
          }

          // Set The Departments
          dispatch(addDepartments([res, first]));
        })
        .catch(err => api.handleErrors(err, departmentError));
    },
    [api, departments, dispatch, error, limit, total, loading, offset]
  );

  // Fetching The User Departments If They Dont Exist
  useEffect(() => {
    // Don't Fetch On Load If There Already Departments
    if (
      (departments.length > 0 && departments[0].departments.length > 0) ||
      total >= 0
    ) {
      return;
    }

    // Fetching Departments
    fetchDepartments(true);
  }, [departments, fetchDepartments, total]);

  /**
   * Find A User's Current Department
   *
   * @param id - The Department Id
   * @returns The Associated Department
   */
  const findCurrent = (id: string): Drivn.Department | undefined => {
    const set = departments.find(
      ({ departments }) => departments.filter(ele => ele.id === id).length > 0
    );

    return set === undefined
      ? undefined
      : set.departments.find(ele => ele.id === id);
  };

  return {
    departments,
    loading,
    error,
    user,
    limit,
    total,
    setLimit,
    offset,
    setOffset,
    updateDepartment,
    addDepartment,
    tempDepartment,
    findCurrentDep: findCurrent
  };
};

// Exporting The Hook
export default useDepartments;
