import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Id, toast } from "react-toastify";

interface UserCreateOrUpdate extends Drivn.UserForCreate {
  uid?: string
}

interface LocalEmployees {
  employees: { employees: Drivn.User[] }[];
  total: number;
  loading: boolean;
  error: import("samuel-types").Err | undefined;
  limit: number;
  tempEmployee: UserCreateOrUpdate | undefined;
}

// The InitialState For The Slice
const initialState: LocalEmployees = {
  employees: [{ employees: [] }],
  total: -1,
  loading: false,
  error: undefined,
  limit: 20,
  tempEmployee: undefined
};

// The Employees Redux Slice
const Employeeslice = createSlice({
  name: "Employees",
  initialState,
  reducers: {
    addEmployee: (state, { payload }: PayloadAction<Drivn.User>) => ({
      total: state.total + 1,
      employees: updateAddEmployee(state.employees, payload, state.limit),
      loading: false,
      error: undefined,
      limit: state.limit,
      tempEmployee: undefined
    }),
    startEmployeeUpdate: state => ({ ...state, loading: true }),
    employeeError: (
      state,
      newValue: PayloadAction<[import("samuel-types").Err, Id | undefined]>
    ) => {
      if (newValue.payload[1]) {
        toast.update(newValue.payload[1], {
          render: newValue.payload[0].message,
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
      } else {
        // Toast
        toast.error(newValue.payload[0].message);
      }

      // Setting The State
      return {
        ...state,
        loading: false,
        error: newValue.payload[0]
      };
    },
    addEmployees: (
      state,
      { payload }: PayloadAction<[Drivn.EmployeeResponse, boolean]>
    ) => ({
      ...state,
      total: payload[0].total < 0 ? 0 : payload[0].total,
      employees:
        payload[1]
          ? [{ employees: payload[0].employees }]
          : [...state.employees, { employees: payload[0].employees }],
      loading: false,
      error: undefined
    }),
    updateEmployee: (state, { payload }: PayloadAction<Drivn.User>) =>
      // Returning The Updated State
      ({
        ...state,
        total: state.total,
        limit: state.limit,
        employees: state.employees.map(set => {
          if (set.employees.filter(off => off.uid === payload.uid)) {
            return {
              employees: set.employees.map(off => {
                if (off.uid === payload.uid) {
                  return payload;
                }
                return off;
              })
            };
          }

          return set;
        }),
        loading: false,
        error: undefined,
        tempEmployee: undefined
      }),
    updateTempEmployee: (
      state,
      newValue: PayloadAction<Drivn.UserForCreate | undefined>
    ) => ({
      ...state,
      tempEmployee: newValue.payload
    }),
    removeAllEmployees: () => initialState
  }
});

const updateAddEmployee = (
  off: { employees: Drivn.User[] }[],
  newItem: Drivn.User,
  limit: number
) => {
  if (off[off.length - 1].employees.length >= limit) {
    return [...off, { employees: [newItem] }];
  }
  return off.map((set, index) => {
    if (index === off.length - 1) {
      return { employees: [...set.employees, newItem] };
    }

    return set;
  });
};

// Exporting The Actions
export const {
  addEmployee,
  addEmployees,
  removeAllEmployees,
  startEmployeeUpdate,
  employeeError,
  updateEmployee,
  updateTempEmployee
} = Employeeslice.actions;

// Exporting The Slice
export default Employeeslice;
