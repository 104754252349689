import React, { useEffect } from "react";

import Chart from "react-apexcharts";

// Hooks
import { setDocumentTitle } from "@hooks/document-title";
import { useDarkMode } from "@hooks/dark-mode";

// Charts
import EmissionsByActivityType from "@components/charts/pie-chart";
import TotalCO2Emissions from "@components/charts/med-line-chart";
import BigEmissions from "@components/charts/big-line-chart";
import { parseAbsoluteToLocal } from "@internationalized/date";
import useAnalytics from "@hooks/analytics";

// UI Components
import { DateRangePicker, Provider, defaultTheme } from "@adobe/react-spectrum";
import { Slab } from "react-loading-indicators";
import useOrganization from "@hooks/organization";
import { setDates, useAppDispatch } from "@util";

const Analytics = () => {
  // Called Before The View Loads
  useEffect(() => {
    // Setting The Document Title
    setDocumentTitle("Analytics");
  }, []);

  // Analytics
  const analytics = useAnalytics();

  const org = useOrganization();

  // Dark Mode
  const dark = useDarkMode();

  // App Dispatch
  const dispatch = useAppDispatch();

  return (
    <>
      {analytics.loading ? (
        <div className="w-full min-h-[calc(100vh-110px)] mt-[110px] flex justify-center items-center">
          <Slab
            // A variant="bob"
            color={dark.dark ? "#80abf9" : "#1673ff"}
            size="large"
            text="Loading Analytics..."
            textColor={dark.dark ? "#fff" : "#000"}
          />
        </div>
      ) : (
        <div className="px-4 py-6 mt-[110px]">
          <div className="flex justify-between items-center mb-4">
            <div className="organization-name">
              <h1 className="text-2xl font-bold ">{org.organization?.name}</h1>
            </div>
            <Provider
              theme={{
                ...defaultTheme
              }}
            >
              <DateRangePicker
                minValue={
                  org.organization?.createdAt &&
                  parseAbsoluteToLocal(
                    org.organization.createdAt as unknown as string
                  )
                }
                maxValue={parseAbsoluteToLocal(new Date().toISOString())}
                value={{
                  start: parseAbsoluteToLocal(analytics.start),
                  end: parseAbsoluteToLocal(analytics.end)
                }}
                onChange={val => {
                  dispatch(setDates(val));
                  analytics.fetchRecent(
                    val.start.toAbsoluteString(),
                    val.end.toAbsoluteString()
                  );
                }}
              />
            </Provider>
          </div>
          <BigEmissions />
          <div className="mt-4 grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
            <TotalCO2Emissions />
            <UserSignupsThisWeek />
            <EmissionsByActivityType />
          </div>
          {/* <div className="my-4 grid grid-cols-1 xl:gap-4 2xl:grid-cols-3 mb-10"> */}
          {/* <CountryMapChart /> */}
          {/* </div> */}
        </div>
      )}
    </>
  );
};

const UserSignupsThisWeek: React.FC = () => {
  const analytics = useAnalytics();

  return (
    <div className="rounded-lg bg-secBg p-4 shadow sm:p-6 xl:p-8">
      <div className="flex items-center">
        <div className="shrink-0">
          <span className="text-2xl font-bold leading-none text-black sm:text-3xl">
            {
              Object.values(analytics.recentAnalytics)
                .map(rec => rec.tripIds)
                .flat().length
            }
          </span>
          <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
            Amount of commutes
          </h3>
        </div>
        {/* <div className="ml-5 flex w-0 flex-1 items-center justify-end text-base font-bold text-red-600 dark:text-red-400">
          -2.7%
          <svg
            className="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div> */}
      </div>
      <UserSignupsChart />
    </div>
  );
};

const UserSignupsChart: React.FC = () => {
  const { dark: isDarkTheme, primary } = useDarkMode();

  const analytics = useAnalytics();

  const backgroundBarColors = isDarkTheme
    ? Array(7).fill("#374151")
    : Array(7).fill("#E5E7EB");

  const options: ApexCharts.ApexOptions = {
    labels: Object.keys(analytics.recentAnalytics),
    chart: {
      foreColor: "#4B5563",
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: true
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        color: primary
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "25%",
        borderRadius: 3,
        colors: {
          backgroundBarColors,
          backgroundBarRadius: 3
        }
      }
    },
    xaxis: {
      floating: true,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    tooltip: {
      hideEmptySeries: false,
      shared: true,
      intersect: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif"
      }
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.8
        }
      }
    },
    fill: {
      opacity: 1
    },
    yaxis: {
      show: false
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true
    }
  };
  const series = [
    {
      name: "Commutes",
      data: Object.values(analytics.recentAnalytics).map(
        val => val.tripIds.length
      )
    }
  ];

  return <Chart height={305} options={options} series={series} type="bar" />;
};

export default Analytics;
