import { useEffect, useState } from "react";

// Helpers
import {
  addOffice as dispatchOfficeCreate,
  updateOffice as dispatchOfficeUpdate,
  addOffices,
  Err,
  useAppSelector,
  useAppDispatch,
  officeError,
  startOfficeUpdate
} from "@util";
import { useApi, useOrgCode } from "@hooks/api";
import { useUser } from "@hooks/user";
import { toast } from "react-toastify";

const useOffices = () => {
  // The User's Offices
  const { offices, loading, error, total, tempOffice } = useAppSelector(
    state => state.offices
  );

  // The User's Offset
  const [offset, setOffset] = useState(0);

  // The Limit For The Offices
  const [limit, setLimit] = useState(20);

  // The User
  const user = useUser();

  // The API
  const api = useApi();

  // The App Dispatch
  const dispatch = useAppDispatch();

  // Fetching The User Offices If They Dont Exist
  useEffect(() => {
    // Don't Fetch On Load If There Already Offices
    if ((offices.length > 0 && offices[0].offices.length > 0) || total >= 0) {
      return;
    }

    // Fetching Offices
    fetchOffices(true);
    // eslint-disable-next-line
  }, []);

  /**
   * Create A New Office
   *
   * @param office - The Office For The API Call
   */
  const addOffice = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing Default
    event.preventDefault();

    // Making Sure The Office Exists
    if (tempOffice === undefined) {
      throw new Error("should not happen");
    }

    // Setting Loading
    dispatch(startOfficeUpdate());

    // Setting The Toast
    const id = toast.loading("Creating office...");

    // Fetch The Offices
    api
      .requestWithJSON<Drivn.Office>(
        "/organization/office",
        true,
        "POST",
        tempOffice
      )
      .then(([, res]) => {
        // Handling Errors
        if (isError(res)) {
          dispatch(officeError([res, id]));
          return;
        }

        // Updating The Toast
        toast.update(id, {
          render: "Office created successfully",
          type: "success",
          autoClose: 5000,
          isLoading: false
        });

        // Set The Offices
        dispatch(dispatchOfficeCreate(res));
      })
      .catch(err => api.handleErrors(err, officeError, id));
  };

  const isError = (object: any): object is Err =>
    "relatedVar" in object && "name" in object && "message" in object;

  /**
   * Update An Existing Office
   *
   * @param update - The Office Fields To Update
   */
  const updateOffice = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing Default
    event.preventDefault();

    // Making Sure The Office Exists
    if (tempOffice === undefined) {
      throw new Error("should not happen");
    }

    // Setting Loading
    dispatch(startOfficeUpdate());

    // Setting The Toast
    const id = toast.loading("Updating office...");

    // Fetch The Offices
    api
      .requestWithJSON<Drivn.Office>(
        `/organization/office/${tempOffice.id!}`,
        true,
        "PUT",
        tempOffice
      )
      .then(([, res]) => {
        // Handling Errors
        if (isError(res)) {
          dispatch(officeError([res, id]));
          return;
        }

        // Updating The Toast
        toast.update(id, {
          render: "Office updated successfully",
          type: "success",
          autoClose: 5000,
          isLoading: false
        });

        // Set The Offices
        dispatch(dispatchOfficeUpdate(res));
      })
      .catch(err => api.handleErrors(err, officeError, id));
  };

  /**
   * Fetch Offices
   */
  const fetchOffices = (first: boolean = false) => {
    // Making Sure The Offices Don't Exist
    if (loading || error !== undefined || offices.length === total) {
      return;
    }

    // Setting Loading
    dispatch(startOfficeUpdate());

    // Fetch The Offices
    api
      .requestWithJSON<Drivn.OfficeResponse>(
        `/organization/office/all/${useOrgCode}?limit=${limit}&offset=${offset}`,
        true,
        "GET"
      )
      .then(([, res]) => {
        // Handling Errors
        if (res instanceof Err) {
          dispatch(officeError([res, undefined]));
          return;
        }

        // Set The Offices
        dispatch(addOffices([res, first]));
      })
      .catch(err => api.handleErrors(err, officeError));
  };

  /**
   * Find A User's Current Office
   *
   * @param id - The Office Id
   * @returns The Associated Office
   */
  const findCurrent = (id: string): Drivn.Office | undefined => {
    const set = offices.find(
      ({ offices }) => (offices ?? []).filter(ele => ele.id === id).length > 0
    );

    return set === undefined
      ? undefined
      : set.offices.find(ele => ele.id === id);
  };

  return {
    offices,
    loading,
    error,
    user,
    limit,
    total,
    setLimit,
    offset,
    setOffset,
    updateOffice,
    addOffice,
    tempOffice,
    findCurrent
  };
};

// Exporting The Hook
export default useOffices;
