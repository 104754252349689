import React from "react";

// Hooks
import { useDarkMode } from "@hooks/dark-mode";
import { useUser } from "@hooks/user";

// Routing
import { Outlet, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Routes } from "@routes";

// Components
import { ThemeSwitch } from "@components/theme-switch";
import Footer from "@components/navigation/footer";
import { Slab } from "react-loading-indicators";
import ConfigureToast from "@components/toast";
import NotAdmin from "./subviews/not-admin";
import { toast } from "react-toastify";

export default function Dashboard() {
  // Dark Mode
  const dark = useDarkMode();

  // All The Tabs
  const tabs = [
    {
      title: "Analytics",
      path: Routes.Dashboard.Analytics,
      adminOnly: true
    },
    {
      title: "Employees",
      path: Routes.Dashboard.Employees,
      adminOnly: true
    },
    {
      title: "Departments",
      path: Routes.Dashboard.Departments,
      adminOnly: true
    },
    {
      title: "Offices",
      path: Routes.Dashboard.Offices,
      adminOnly: true
    },
    {
      title: "Settings",
      path: Routes.Dashboard.Settings,
      adminOnly: true
    }
  ];

  // Firebase
  const user = useUser(true);

  // The Location Of The App
  const location = useLocation();

  return (
    <div className="antialiased bg-bg">
      <header className="z-50 fixed top-0">
        <nav className="bg-secBg border-gray-200 px-4 py-3">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center">
              <img
                src={dark.getImage()}
                className="mr-3 h-8"
                alt="Drivn Logo"
              />
            </div>
            <div className="flex justify-between items-center text-sm space-x-4 lg:order-2">
              <ThemeSwitch />
              <div className="h-4 w-px mx-2 border dark:border-gray-700"></div>
              <button
                onClick={user.signOut}
                className="font-medium text-main hover:underline"
              >
                Logout
              </button>
            </div>
          </div>
        </nav>

        <nav className="bg-secBg w-screen border-b border-t border-gray-600">
          <div className="px-4 py-2 flex items-center justify-between">
            <ul className="whitespace-nowrap flex items-center text-sm text-gray-600 font-medium overflow-scroll no-scrollbar">
              {tabs.map(tab => (
                <li key={tab.title} className="block lg:inline">
                  <Link
                    to={tab.path.path}
                    className={`inline-block px-3 py-2 ${
                      location.pathname === tab.path.absolutePath
                        ? "text-main"
                        : "text-black"
                    } rounded-lg hover:text-main hover:bg-bg transition-all`}
                  >
                    {tab.title}
                  </Link>
                </li>
              ))}
            </ul>
            {user.user && (
              <p className="font-medium text-m mr-2">
                Organization Code:{" "}
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(user.user!.organizationId);
                    toast.success("Copied to clipboard");
                  }}
                  className="text-main"
                >
                  {user.user!.organizationId}
                </button>
              </p>
            )}
          </div>
        </nav>
      </header>
      {user.user ? (
        <>{user.user.isAdmin ? <Outlet /> : <NotAdmin user={user.user} />}</>
      ) : (
        <div className="w-full min-h-[calc(100vh-110px)] mt-[110px] flex justify-center items-center">
          <Slab
            color={dark.dark ? "#80abf9" : "#1673ff"}
            size="large"
            text="Loading User..."
            textColor={dark.dark ? "#fff" : "#000"}
          />
        </div>
      )}

      <ConfigureToast />
      <Footer />
    </div>
  );
}
