import React, { useEffect } from "react";

// Helpers
import { Slab } from "react-loading-indicators";

// Hooks
import { setDocumentTitle } from "@hooks/document-title";
import { useDarkMode } from "@hooks/dark-mode";
import useOffices from "@hooks/offices";

// Subviews
import OfficeDrawer from "./subviews/office-drawer";
import OfficeCell from "./subviews/office-cell";
import DeleteModal from "@components/delete-modal";
import { updateTempOffice, useAppDispatch } from "@util";

export default function Offices() {
  // The User Offices
  const off = useOffices();

  // App Dispatch
  const dispatch = useAppDispatch();

  // The Current Dark Mode
  const dark = useDarkMode();

  // Called Before The View Loads
  useEffect(() => {
    // Setting The Document Title
    setDocumentTitle("Offices");
  }, []);

  return (
    <div className="mt-[110px] overflow-x-hidden">
      {off.loading && off.total < 0 ? (
        <div className="w-full min-h-[calc(100vh-110px)] flex justify-center items-center">
          <Slab
            color={dark.dark ? "#80abf9" : "#1673ff"}
            size="large"
            text="Loading Offices..."
            textColor={dark.dark ? "#fff" : "#000"}
          />
        </div>
      ) : (
        <>
          {/* Start block */}
          <section className="bg-bg py-3 relative sm:py-5 antialiased min-h-[calc(100vh-110px)]">
            <div className="mx-auto max-w-screen-2xl px-4 lg:px-12">
              <div className="bg-secBg relative shadow-md sm:rounded-lg">
                <div className="flex flex-col md:flex-row md:items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                  <div className="w-full md:w-1/2">
                    <form className="flex items-center">
                      {/* <label htmlFor="simple-search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="simple-search"
                          className="bg-bg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:border-gray-600 dark:placeholder-gray-400"
                          placeholder="Search"
                          required={false}
                        />
                      </div> */}
                      <h1 className="font-semibold text-2xl ml-5">Offices</h1>
                    </form>
                  </div>
                  <div className="flex flex-row items-center justify-between md:justify-end md:space-x-3 flex-shrink-0">
                    <div className="flex items-center space-x-3">
                      <button
                        type="button"
                        onClick={() =>
                          dispatch(
                            updateTempOffice({
                              name: "",
                              estimatedEmployeeCount: 0,
                              city: "",
                              zip: "",
                              streetNameAndNumber: "",
                              state: "",
                              orgId: "",
                              color: "",
                              lat: 0,
                              lng: 0,
                              radius: 150,
                              removed: false
                            })
                          )
                        }
                        className="flex items-center transition-all justify-center text-black bg-bg hover:bg-main hover:text-bg font-medium rounded-lg text-sm px-4 py-2 border border-[rgba(255,255,255,0.5)] hover:border-none"
                      >
                        <svg
                          className="h-3.5 w-3.5 mr-2 flex-shrink-0 hidden md:inline"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          />
                        </svg>
                        Add Office
                      </button>
                    </div>
                    <div className="flex items-center space-x-1">
                      {/* <button
                    className="flex items-center justify-center p-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500 dark:text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <button
                    className="flex items-center justify-center p-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500 dark:text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                    </svg>
                  </button> */}
                    </div>
                  </div>
                </div>
                <div className="overflow-x-auto mx-4 space-y-4">
                  {off.offices[off.offset].offices.map(office => (
                    <OfficeCell key={office.id} office={office} />
                  ))}
                  <nav
                    className="flex flex-col items-center justify-center space-y-3 py-5"
                    aria-label="Table navigation"
                  >
                    <div className="flex items-center">
                      <div className="text-xs font-normal text-gray-500 dark:text-gray-400">
                        Showing&ensp;
                        <b className="font-semibold text-black">
                          {off.offset * off.limit - 1 < 0
                            ? 1
                            : off.offset * off.limit}
                          &ensp;
                        </b>
                        to&ensp;
                        <b className="font-semibold text-black">
                          {(off.offset + 1) * off.limit > off.total
                            ? off.total
                            : off.offset * off.limit}
                          &ensp;
                        </b>
                        of&ensp;
                        <b className="font-semibold text-black">
                          {off.total}&ensp;
                        </b>
                        offices
                      </div>
                    </div>
                    <ul className="inline-flex items-stretch -space-x-px">
                      <li>
                        <button
                          onClick={() => off.setOffset(off.offset - 1)}
                          disabled={off.offset === 0}
                          className="flex opacity-50 enabled:opacity-100 text-sm w-20 items-center justify-center h-full py-1.5 px-3 ml-0 text-black bg-bg rounded-l-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg transition-all enabled:hover:border-main dark:border-gray-700"
                        >
                          Prev
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => off.setOffset(off.offset + 1)}
                          disabled={off.offset === off.offices.length - 1}
                          className="flex opacity-50 enabled:opacity-100 text-sm w-20 items-center justify-center h-full py-1.5 px-3 leading-tight text-black bg-bg rounded-r-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg transition-all enabled:hover:border-main dark:border-gray-700"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </section>

          <OfficeDrawer />
          <DeleteModal />
        </>
      )}
    </div>
  );
}
