import React from "react";

// Helpers
import { updateTempDepartment, useAppDispatch } from "@util";

// An Department Cell
const DepartmentCell = ({ department }: DepartmentCellProps) => {
  // App Dispatch
  const dispatch = useAppDispatch();

  return (
    <tr
      className="border-b dark:border-gray-700 hover:bg-bg transition-all"
      onClick={() => dispatch(updateTempDepartment(department))}
    >
      <th
        scope="row"
        className="px-4 py-3 font-medium text-black whitespace-nowrap"
      >
        {department.name}
      </th>
      <td className="px-4 py-3">{department.employeeCount}</td>
      <td className="px-4 py-3">{department.employees.length}</td>
      <td className="px-4 py-3 max-w-[12rem] truncate">
        0 km
      </td>
      <td className="px-4 py-3">0 km</td>
    </tr>
  );
};

export default DepartmentCell;
