import React from "react";

// Charts
import Chart from "react-apexcharts";

// UI Components
import { useDarkMode } from "@hooks/dark-mode";
import useAnalytics from "@hooks/analytics";

export const EmissionsByActivityType = () => {
  // Analytics
  const analytics = useAnalytics();

  const series = () => {
    const vals = Object.values(analytics.recentAnalytics)
      .map(val => val.activityTypes)
      .flat();

    const toReturn: Record<string, number> = {};

    vals.forEach(val => {
      if (toReturn[val.activityType]) {
        toReturn[val.activityType] = val.emissions;
      } else {
        toReturn[val.activityType] = val.emissions;
      }
    });

    const highest = Object.keys(toReturn).find(
      val => toReturn[val] === Math.max(...Object.values(toReturn))
    );

    return (
      (highest ?? "").charAt(0).toUpperCase() +
      (highest ?? "").slice(1).toLowerCase()
    );
  };

  return (
    <div className="rounded-lg bg-secBg p-4 shadow sm:p-6 xl:p-8">
      <div className="flex items-center">
        <div className="shrink-0">
          <span className="text-2xl font-bold leading-none text-black sm:text-3xl">
            {series()}
          </span>
          <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
          Most Utilized Transportation Method
          </h3>
        </div>
        {/* <div className="ml-5 flex w-0 flex-1 items-center justify-end text-base font-bold text-red-600 dark:text-red-400">
          -2.7%
          <svg
            className="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div> */}
      </div>
      <ActivityTypeChart />
    </div>
  );
};

export const ActivityTypeChart: React.FC = function () {
  const { dark: isDarkTheme } = useDarkMode();

  // Analytics
  const analytics = useAnalytics();

  const series = () => {
    const vals = Object.values(analytics.recentAnalytics)
      .map(val => val.activityTypes)
      .flat();

    const toReturn: Record<string, { distance: number; emissions: number }> =
      {};

    vals.forEach(val => {
      if (toReturn[val.activityType]) {
        toReturn[val.activityType].emissions += val.emissions;
        toReturn[val.activityType].distance += val.distance;
      } else {
        toReturn[val.activityType] = {
          emissions: val.emissions,
          distance: val.distance
        };
      }
    });

    return toReturn;
  };

  const options: ApexCharts.ApexOptions = {
    labels: Object.keys(series()),
    colors: ["#16BDCA", "#FDBA8C", "#1A56DB", "#D61F69", "#9061F9", "#6875F5"],
    chart: {
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: true
      },
      foreColor: isDarkTheme ? "#A1A5B2" : "#4B5563"
    },
    yaxis: [
      {
        labels: {
          formatter: val => `${val.toFixed(0)} km`
        }
      }
      // {
      //   Opposite: true,
      //   Labels: {
      //     Formatter: val => `${val.toFixed(2)} kg Co2`
      //   }
      // }
    ],
    stroke: {
      colors: [isDarkTheme ? "#111827" : "#fff"]
    },
    plotOptions: {
      pie: {
        donut: {
          size: "5%"
        }
      }
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      fillSeriesColor: false,
      inverseOrder: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif"
      },
      x: {
        show: true,
        formatter: (val, { dataPointIndex, w }) =>
          w.config.labels[dataPointIndex]
      },
      y: {
        formatter: (value, opts) =>
          `${value.toFixed(2)}${
            (opts.series[0] as number[]).includes(value) ? " kg Co2" : " km"
          }`
      }
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true
    }
  };

  return (
    <Chart
      height={305}
      options={options}
      series={[
        {
          data: Object.values(series()).map(val => val.emissions),
          name: "Emissions"
        },
        {
          data: Object.values(series()).map(val => val.distance),
          name: "Distance"
        }
      ]}
      type="bar"
    />
  );
};

export default EmissionsByActivityType;
