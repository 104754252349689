import React, { useEffect } from "react";

// Helpers
import { Slab } from "react-loading-indicators";

// Hooks
import { setDocumentTitle } from "@hooks/document-title";
import { useDarkMode } from "@hooks/dark-mode";
import useDepartments from "@hooks/departments";

// Subviews
import { updateTempDepartment, useAppDispatch } from "@util";
import DepartmentDrawer from "./subviews/department-modal";
import DepartmentCell from "./subviews/department-cell";

export default function Departments() {
  // The User Departments
  const dep = useDepartments();

  // App Dispatch
  const dispatch = useAppDispatch();

  // The Current Dark Mode
  const dark = useDarkMode();

  // Called Before The View Loads
  useEffect(() => {
    // Setting The Document Title
    setDocumentTitle("Departments");
  }, []);

  return (
    <div className="mt-[110px] overflow-x-hidden">
      {dep.loading && dep.total < 0 ? (
        <div className="w-full min-h-[calc(100vh-110px)] flex justify-center items-center">
          <Slab
            // A variant="bob"
            color={dark.dark ? "#80abf9" : "#1673ff"}
            size="large"
            text="Loading Departments..."
            textColor={dark.dark ? "#fff" : "#000"}
          />
        </div>
      ) : (
        <>
          <section className="bg-bg p-3 sm:p-5 min-h-[calc(100vh-110px)] antialiased">
            <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
              {/* Start coding here */}
              <div className="bg-secBg relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                  <div className="w-full md:w-1/2">
                    <form className="flex items-center">
                      <label htmlFor="simple-search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="simple-search"
                          className="bg-bg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Search"
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                    <button
                      onClick={() =>
                        dispatch(
                          updateTempDepartment({
                            name: "",
                            employeeCount: 0,
                            orgId: "",
                            travelsForBusiness: false,
                            color: "#FFFF",
                            removed: false
                          })
                        )
                      }
                      type="button"
                      id="createProductModalButton"
                      className="flex items-center justify-center text-black hover:text-bg hover:bg-main transition-all focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 bg-bg focus:outline-none"
                    >
                      <svg
                        className="h-3.5 w-3.5 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        />
                      </svg>
                      Add Department
                    </button>
                    {/* <div className="flex items-center space-x-3 w-full md:w-auto">
                      <button
                        id="filterDropdownButton"
                        data-dropdown-toggle="filterDropdown"
                        className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          className="h-4 w-4 mr-2 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Filter
                        <svg
                          className="-mr-1 ml-1.5 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          />
                        </svg>
                      </button>
                      <div
                        id="filterDropdown"
                        className="z-10 hidden w-56 p-3 bg-white rounded-lg shadow dark:bg-gray-700"
                      >
                        <h6 className="mb-3 text-sm font-medium text-black">
                          Category
                        </h6>
                        <ul
                          className="space-y-2 text-sm"
                          aria-labelledby="filterDropdownButton"
                        >
                          <li className="flex items-center">
                            <input
                              id="apple"
                              type="checkbox"
                              defaultValue=""
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="apple"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Apple (56)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="fitbit"
                              type="checkbox"
                              defaultValue=""
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="fitbit"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Fitbit (56)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="dell"
                              type="checkbox"
                              defaultValue=""
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="dell"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Dell (56)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="asus"
                              type="checkbox"
                              defaultValue=""
                              defaultChecked
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="asus"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Asus (97)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="logitech"
                              type="checkbox"
                              defaultValue=""
                              defaultChecked
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="logitech"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Logitech (97)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="msi"
                              type="checkbox"
                              defaultValue=""
                              defaultChecked
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="msi"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              MSI (97)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="bosch"
                              type="checkbox"
                              defaultValue=""
                              defaultChecked
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="bosch"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Bosch (176)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="sony"
                              type="checkbox"
                              defaultValue=""
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="sony"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Sony (234)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="samsung"
                              type="checkbox"
                              defaultValue=""
                              defaultChecked
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="samsung"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Samsung (76)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="canon"
                              type="checkbox"
                              defaultValue=""
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="canon"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Canon (49)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="microsoft"
                              type="checkbox"
                              defaultValue=""
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="microsoft"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Microsoft (45)
                            </label>
                          </li>
                          <li className="flex items-center">
                            <input
                              id="razor"
                              type="checkbox"
                              defaultValue=""
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                              htmlFor="razor"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                            >
                              Razor (49)
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-secBg border-y border-gray-300 dark:border-gray-500 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-4 py-4">
                          Department name
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Employee Count
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Active Employees
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Total Emissions
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Avg. Employee Emissions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dep.departments[dep.offset].departments.map(dep => (
                        <DepartmentCell key={dep.id} department={dep} />
                      ))}
                    </tbody>
                  </table>
                </div>
                <nav
                  className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
                  aria-label="Table navigation"
                >
                  <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    Showing
                    <span className="font-semibold text-black">
                    &ensp;{dep.offset * dep.limit - 1 < 0
                        ? 1
                        : dep.offset * dep.limit}&ensp;
                      -&ensp;
                      {(dep.offset + 1) * dep.limit > dep.total
                        ? dep.total
                        : dep.offset * dep.limit}&ensp;
                    </span>
                    of
                    <span className="font-semibold text-black">
                    &ensp;{dep.total}
                    </span>
                  </span>
                  <ul className="inline-flex items-stretch -space-x-px">
                    <li>
                      <button
                        onClick={() => dep.setOffset(dep.offset - 1)}
                        disabled={dep.offset === 0}
                        className="flex opacity-50 cursor-not-allowed enabled:cursor-pointer enabled:opacity-100 transition-all items-center justify-center h-full py-1.5 px-3 leading-tight text-black bg-secBg rounded-l-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg enabled:hover:border-main dark:border-gray-700"
                      >
                        <span className="sr-only">Previous</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => dep.setOffset(dep.offset + 1)}
                        disabled={dep.offset === dep.departments.length - 1}
                        className="flex cursor-not-allowed enabled:cursor-pointer opacity-50 enabled:opacity-100 transition-all items-center justify-center h-full py-1.5 px-3 leading-tight text-black bg-secBg rounded-r-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg enabled:hover:border-main dark:border-gray-700"
                      >
                        <span className="sr-only">Next</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </section>
          {/* Create modal */}
          <DepartmentDrawer />
          {/* Delete modal */}
          <div
            id="deleteModal"
            tabIndex={-1}
            aria-hidden="true"
            className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative p-4 w-full max-w-md max-h-full">
              {/* Modal content */}
              <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <button
                  type="button"
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="deleteModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <svg
                  className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="mb-4 text-gray-500 dark:text-gray-300">
                  Are you sure you want to delete this item?
                </p>
                <div className="flex justify-center items-center space-x-4">
                  <button
                    data-modal-toggle="deleteModal"
                    type="button"
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                  <button
                    type="submit"
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                  >
                    Yes, I'm sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
