import React, { useEffect, useState } from "react";

// Routing
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

// Helpers
import {
  Routes,
  capitalizeFirstLetter,
  updateTempEmployee,
  useAppDispatch
} from "@util";
import useEmployees from "@hooks/employees";

// Subviews
import { setDocumentTitle } from "@hooks/document-title";
import EmployeeCell from "./subviews/employee-cell";
import { useDarkMode } from "@hooks/dark-mode";
import { Slab } from "react-loading-indicators";
import { twMerge } from "tailwind-merge";
import { Avatar } from "@assets";

const Employees = () => {
  // The Search Params
  const [searchParams] = useSearchParams();

  // Selected Users
  const [selected, setSelected] = useState<string[]>([]);

  // The Employees Hook
  const employees = useEmployees();

  // Called Before The View Loads
  useEffect(() => {
    // Setting The Document Title
    setDocumentTitle("Employees");
  }, []);

  // The Current Dark Mode
  const dark = useDarkMode();

  // The Dispatch
  const dispatch = useAppDispatch();

  return (
    <>
      {employees.loading && employees.total < 0 ? (
        <div className="w-full min-h-[calc(100vh-110px)] flex justify-center items-center">
          <Slab
            color={dark.dark ? "#80abf9" : "#1673ff"}
            size="large"
            text="Loading Employees..."
            textColor={dark.dark ? "#fff" : "#000"}
          />
        </div>
      ) : (
        <>
          <section className="bg-bg p-3 sm:p-5 antialiased mt-[110px] min-h-[calc(100vh-110px)]">
            <div className="mx-auto max-w-screen-2xl px-4 lg:px-12">
              <div className="bg-secBg relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="px-4">
                  <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-4 py-3">
                    <div>
                      <nav
                        className="mb-2 flex px-3 py-2 text-gray-700 border border-gray-200 rounded-lg bg-bg dark:border-gray-600"
                        aria-label="Breadcrumb"
                      >
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                          <li className="inline-flex items-center">
                            <a
                              href={Routes.Dashboard.path}
                              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-main transition-all dark:text-gray-300"
                            >
                              Dashboard
                            </a>
                          </li>
                          {searchParams.get("entity") !== null && (
                            <li>
                              <div className="flex items-center">
                                <svg
                                  aria-hidden="true"
                                  className="w-5 h-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <Link
                                  to={`/dashboard/${searchParams.get(
                                    "entity"
                                  )}`}
                                  className="ml-1 text-sm font-medium text-gray-700 hover:text-primary-600 md:ml-2 dark:text-gray-300 dark:hover:text-white"
                                >
                                  {capitalizeFirstLetter(
                                    searchParams.get("entity")!
                                  )}
                                </Link>
                              </div>
                            </li>
                          )}
                          <li aria-current="page">
                            <div className="flex items-center">
                              <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                                All Employees
                              </span>
                            </div>
                          </li>
                        </ol>
                      </nav>
                      <h5>
                        <span className="text-2xl font-bold">All Users</span>
                      </h5>
                    </div>
                    <div className="w-full md:w-auto flex-shrink-0 flex flex-col items-start md:flex-row md:items-center lg:justify-end space-y-3 md:space-y-0 md:space-x-3">
                      <button
                        type="button"
                        id="createUserButton"
                        onClick={() =>
                          dispatch(
                            updateTempEmployee({
                              firstName: "",
                              lastName: "",
                              email: "",
                              password: ""
                            })
                          )
                        }
                        className="w-full md:w-auto flex items-center justify-center text-bg bg-main hover:bg-white hover:text-main transition-all focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none dark:focus:ring-primary-800"
                      >
                        <svg
                          className="h-3.5 w-3.5 mr-1.5 -ml-1"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          />
                        </svg>
                        Add new user
                      </button>
                    </div>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-bg border-y border-gray-300 dark:border-gray-500 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="p-4">
                          <div className="flex items-center">
                            <input
                              id="checkbox-all"
                              type="checkbox"
                              className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor="checkbox-all" className="sr-only">
                              checkbox
                            </label>
                          </div>
                        </th>
                        <th scope="col" className="px-4 py-3">
                          User
                        </th>
                        <th scope="col" className="px-4 py-3">
                          User Role
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Office
                        </th>
                        <th scope="col" className="px-4 py-3 whitespace-nowrap">
                          Department
                        </th>
                        <th scope="col" className="px-4 py-3">
                          CO2 Emissions
                        </th>
                        <th scope="col" className="px-4 py-3 whitespace-nowrap">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees.employees[employees.offset].employees.map(
                        (user, int) => (
                          <EmployeeCell
                            user={user}
                            selected={selected}
                            setSelected={setSelected}
                            key={int}
                          />
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <nav
                  className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
                  aria-label="Table navigation"
                >
                  <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    Showing
                    <span className="font-semibold text-black">
                      &ensp;
                      {employees.offset * employees.limit - 1 < 0
                        ? 1
                        : employees.offset * employees.limit}
                      &ensp; -&ensp;
                      {(employees.offset + 1) * employees.limit >
                      employees.total
                        ? employees.total
                        : employees.offset * employees.limit}
                      &ensp;
                    </span>
                    of
                    <span className="font-semibold text-black">
                      &ensp;{employees.total}
                    </span>
                  </span>
                  <ul className="inline-flex items-stretch -space-x-px">
                    <li>
                      <button
                        onClick={() =>
                          employees.setOffset(employees.offset - 1)
                        }
                        disabled={employees.offset === 0}
                        className="flex opacity-50 cursor-not-allowed enabled:cursor-pointer enabled:opacity-100 transition-all items-center justify-center h-full py-1.5 px-3 leading-tight text-black bg-secBg rounded-l-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg enabled:hover:border-main dark:border-gray-700"
                      >
                        <span className="sr-only">Previous</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          employees.setOffset(employees.offset + 1)
                        }
                        disabled={
                          employees.offset === employees.employees.length - 1
                        }
                        className="flex cursor-not-allowed enabled:cursor-pointer opacity-50 enabled:opacity-100 transition-all items-center justify-center h-full py-1.5 px-3 leading-tight text-black bg-secBg rounded-r-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg enabled:hover:border-main dark:border-gray-700"
                      >
                        <span className="sr-only">Next</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </section>
          {/* Create User Modal */}
          <div
            id="createUserdefaultModal"
            tabIndex={-1}
            aria-hidden="true"
            className={twMerge(
              "overflow-y-hidden overflow-x-hidden fixed z-50 w-full md:inset-0 bg-[#000] h-screen bg-opacity-30 max-h-full transition-all",
              employees.tempEmployee ? "" : "opacity-0 pointer-events-none"
            )}
          >
            <div className="flex justify-center items-center h-full">
              <div className="relative p-4 w-full max-w-2xl max-h-full">
                {/* Modal content */}
                <div className="relative p-4 bg-bg rounded-lg shadow sm:p-5">
                  {/* Modal header */}
                  <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                    <h3 className="text-lg font-semibold text-black">
                      Add new user
                    </h3>
                    <button
                      type="button"
                      onClick={() => dispatch(updateTempEmployee(undefined))}
                      className="text-gray-400 transition-all bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-main hover:text-bg"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body */}
                  <form action="#">
                    <div className="grid gap-4 mb-4 sm:grid-cols-2">
                      <div>
                        <label
                          htmlFor="first-name"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="John"
                          required
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="last-name"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Doe"
                          required
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="name@company.com"
                          required
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="user-permissions"
                          className="inline-flex items-center mb-2 text-sm font-medium text-black"
                        >
                          User Permissions
                          <button
                            type="button"
                            data-tooltip-target="tooltip-permissions"
                            data-tooltip-style="dark"
                            className="ml-1"
                          >
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 text-gray-400 cursor-pointer hover:text-gray-900 dark:hover:text-white dark:text-gray-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span className="sr-only">Details</span>
                          </button>
                          <div
                            id="tooltip-permissions"
                            role="tooltip"
                            className="inline-block absolute invisible z-10 py-2 px-3 max-w-sm text-xs font-normal text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                          >
                            User permissions, part of the overall user
                            management process, are access granted to users to
                            specific resources such as files, applications,
                            networks, or devices.
                            <div
                              className="tooltip-arrow"
                              data-popper-arrow=""
                            />
                          </div>
                        </label>
                        <select
                          id="user-permissions"
                          className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        >
                          <option selected value={"YES"}>
                            Administrator
                          </option>
                          <option defaultChecked value="NO">
                            Employee
                          </option>
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="password"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="•••••••••"
                          required
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="confirm-password"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Confirm password
                        </label>
                        <input
                          type="password"
                          name="confirm-password"
                          id="confirm-password"
                          className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="•••••••••"
                          required
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          className="block mb-2 text-sm font-medium text-black"
                          htmlFor="file_input"
                        >
                          Upload avatar
                        </label>
                        <div className="items-center w-full sm:flex">
                          <img
                            className="mb-4 w-20 h-20 rounded-full sm:mr-4 sm:mb-0"
                            src={employees.tempEmployee?.image ?? Avatar}
                            alt="avatar"
                          />
                          <div className="w-full">
                            <input
                              className="w-full text-sm text-black bg-secBg rounded-lg border border-gray-300 cursor-pointer focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                              aria-describedby="file_input_help"
                              id="file_input"
                              type="file"
                            />
                            <p
                              className="mt-1 text-xs font-normal text-gray-500 dark:text-gray-300"
                              id="file_input_help"
                            >
                              SVG, PNG, JPG or GIF (MAX. 800x400px).
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={() => dispatch(updateTempEmployee(undefined))}
                        type="button"
                        className="w-full inline-flex justify-center transition-all text-red-500 items-center bg-secBg hover:bg-red-500 hover:text-[#fff] focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:border-gray-500 dark:focus:ring-gray-600"
                      >
                        Discard
                      </button>
                      <button
                        type="submit"
                        className="w-full text-bg hover:text-black hover:bg-white inline-flex items-center justify-center transition-all bg-main focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-800"
                      >
                        <svg
                          className="-ml-1 w-5 h-5 sm:mr-1"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Add new user
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Delete Modal */}
          <div
            id="delete-modal"
            tabIndex={-1}
            className="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  data-modal-toggle="delete-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center">
                  <svg
                    aria-hidden="true"
                    className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you sure you want to delete this product?
                  </h3>
                  <button
                    data-modal-toggle="delete-modal"
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    data-modal-toggle="delete-modal"
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// Exporting
export default Employees;
