import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Id, toast } from "react-toastify";

interface LocalOffices {
  offices: { offices: Drivn.Office[] }[];
  total: number;
  loading: boolean;
  error: import("samuel-types").Err | undefined;
  limit: number;
  tempOffice: OfficeForCreate | undefined;
}

// The InitialState For The Slice
const initialState: LocalOffices = {
  offices: [{ offices: [] }],
  total: -1,
  loading: false,
  error: undefined,
  limit: 20,
  tempOffice: undefined
};

// The Offices Redux Slice
const officeSlice = createSlice({
  name: "offices",
  initialState,
  reducers: {
    addOffice: (state, { payload }: PayloadAction<Drivn.Office>) => ({
      total: state.total + 1,
      offices: updateAddOffice(state.offices, payload, state.limit),
      loading: false,
      error: undefined,
      limit: state.limit,
      tempOffice: undefined
    }),
    startOfficeUpdate: state => ({ ...state, loading: true }),
    officeError: (
      state,
      newValue: PayloadAction<[import("samuel-types").Err, Id | undefined]>
    ) => {
      if (newValue.payload[1]) {
        toast.update(newValue.payload[1], {
          render: newValue.payload[0].message,
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
      } else {
        // Toast
        toast.error(newValue.payload[0].message);
      }

      // Setting The State
      return {
        ...state,
        loading: false,
        error: newValue.payload[0]
      };
    },
    addOffices: (
      state,
      { payload }: PayloadAction<[Drivn.OfficeResponse, boolean]>
    ) => ({
      ...state,
      total: payload[0].total < 0 ? 0 : payload[0].total,
      offices:
        payload[1]
          ? [{ offices: payload[0].offices }]
          : [...state.offices, { offices: payload[0].offices }],
      loading: false,
      error: undefined
    }),
    updateOffice: (state, { payload }: PayloadAction<Drivn.Office>) =>
      // Returning The Updated State
      ({
        ...state,
        total: state.total,
        limit: state.limit,
        offices: state.offices.map(set => {
          if (set.offices.filter(off => off.id === payload.id)) {
            return {
              offices: set.offices.map(off => {
                if (off.id === payload.id) {
                  return payload;
                }
                return off;
              })
            };
          }

          return set;
        }),
        loading: false,
        error: undefined,
        tempOffice: undefined
      }),
    updateTempOffice: (
      state,
      newValue: PayloadAction<OfficeForCreate | undefined>
    ) => ({
      ...state,
      tempOffice: newValue.payload
    }),
    removeAll: () => initialState
  }
});

const updateAddOffice = (
  off: { offices: Drivn.Office[] }[],
  newItem: Drivn.Office,
  limit: number
) => {
  if (off[off.length - 1].offices.length >= limit) {
    return [...off, { offices: [newItem] }];
  }
  return off.map((set, index) => {
    if (index === off.length - 1) {
      return { offices: [...set.offices, newItem] };
    }

    return set;
  });
};

// Exporting The Actions
export const {
  addOffice,
  addOffices,
  removeAll,
  startOfficeUpdate,
  officeError,
  updateOffice,
  updateTempOffice
} = officeSlice.actions;

// Exporting The Slice
export default officeSlice;
